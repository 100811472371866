.root {
  display: grid;
  align-items: center;

  padding: theme("spacing.sm") 0 calc(theme("spacing.2xs") - 2px);

  font-size: theme("fontSize.default-sm");
  line-height: theme("lineHeight.snug");
  color: theme("colors.secondaryUI.bg");
  border-bottom: 1px solid currentColor;
}

.root:focus-within {
  color: theme("colors.secondaryUI.focus");
}

.root:hover {
  color: theme("colors.secondaryUI.hover");
}

.root label,
.root input {
  grid-column: 1/-1;
  grid-row: 1/-1;
}

.root label {
  transform-origin: center left;
  transition: transform .25s ease;
}

.root:focus-within label,
.root:not([data-state="empty"]) label {
  transform: translateY(-100%) scale(0.75);
}

.root input {
  padding: 0;
  background: transparent;
}

.root input:focus {
  outline: none;
}
