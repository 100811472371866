.root {
  display: grid;
  align-items: center;

  font-size: theme("fontSize.default-sm");
  line-height: theme("lineHeight.snug");
  color: theme("colors.secondaryUI.bg");
}

.root:focus-within {
  color: theme("colors.secondaryUI.focus");
}

.root:hover {
  color: theme("colors.secondaryUI.hover");
}

.root label,
.root select {
  grid-column: 1/-1;
  grid-row: 1/-1;
}

.root label {
  transform-origin: center left;
  transform: translateY(-100%) scale(0.75);
}

.root select {
  display: grid;
  place-items: start;
  padding: theme("spacing.sm") 0 calc(theme("spacing.2xs") - 2px);

  appearance: none;

  background: transparent;
  border-bottom: 1px solid currentColor;
  text-align: start;
}

.root select:focus {
  outline: none;
}

.root option {
  padding: 0;
}

.caret {
  grid-column: 1/-1;
  grid-row: 1/-1;
  justify-self: flex-end;

  pointer-events: none;
}
