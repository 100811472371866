@import "./theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    font-family: theme("fontFamily.sans");
    font-size: 16px;
  }

  body {
    font-size: theme("fontSize.default-md");
    line-height: 1.5;
  }

  html, body {
    min-height: 100vh;
  }

  body {
    display: flex;
    flex-direction: column;
  }

  main {
    flex-grow: 1;
  }

  * {
    mask-repeat: no-repeat;
    scroll-behavior: smooth;
  }

  @media (prefers-reduced-motion) {
    html:focus-within {
      scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }
}
